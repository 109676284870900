require('components/view_loader')

document.addEventListener('DOMContentLoaded', () => {
  let disqus_libs_loaded = false
  let twitter_widget_loaded = false
  let mathjax_library_loaded = false
  let videos_loaded = false

  const loadScriptsIntoFooter = (scriptUrls)  => {
    const partial = document.createElement('div')

    for (let scriptUrl of scriptUrls){
      if(scriptUrl) {
        const s = document.createElement('script')

        s.type = 'text/javascript'
        s.defer = 'defer'
        s.src = scriptUrl

        partial.appendChild(s)
      }
    }

    (document.getElementsByTagName('FOOTER')[0] || document.getElementsByTagName('BODY')[0]).appendChild(partial)
  }

  document.addEventListener('scroll', () => {
    if(!disqus_libs_loaded) {
      lazyLoadDisqus()
      disqus_libs_loaded = true
    }

    if(!twitter_widget_loaded) {
      loadTwitterWidget()
      twitter_widget_loaded = true
    }

    if(!mathjax_library_loaded) {
      loadMathjaxLibrary()
      mathjax_library_loaded = true
    }

    if(!videos_loaded) {
      loadVideos()
    }
  })

  document.addEventListener('click', () => {
    if(!videos_loaded) {
      loadVideos()
    }
  })

  const loadVideos = () => {
    videos_loaded = true
    document.querySelectorAll('.video-preview-image img, .video-preview-image svg').forEach(videoPreviewImage => {
      videoPreviewImage.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }))
    })
  }

  const lazyLoadDisqus = () => {
    //  lazy loading the post comments related libraries only after User scrolls down
    if(Tutsplus.is_post_page && Tutsplus.ext_social) {
      const postCommentsEle = document.getElementById('post_comments')
      if(!postCommentsEle.classList.contains('libraries-loaded')) {
        postCommentsEle.classList.add('libraries-loaded')
        loadScriptsIntoFooter(['https://' + disqus_shortname + '.disqus.com/embed.js', 'https://' + disqus_shortname + '.disqus.com/count.js'])
      }
    }
  }

  const loadTwitterWidget = () => {
    if(Tutsplus.load_twitter_widget) {
      loadScriptsIntoFooter(['https://platform.twitter.com/widgets.js'])
    }
  }

  const loadMathjaxLibrary = () => {
    if(Tutsplus.load_mathjax_library) {
      loadScriptsIntoFooter(['https://cdn.mathjax.org/mathjax/latest/MathJax.js?config=TeX-AMS-MML_HTMLorMML'])
    }
  }
})
