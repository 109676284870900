/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// initializes backbone views for DOM elements based on their class names
// only elements that have the "view" class will be initialized this way

window.ViewLoader = {
  viewNames: {
    'course-picker':                  'CoursePicker',
    'account-header':                 'AccountHeader',
    'course-bookmarks':               'CourseBookmarks',
    'paypal-student-promo':           'PaypalStudentPromo',
    'email-preference-form':          'EmailPreferenceForm',
    'subscription-benefits':          'SubscriptionBenefits',
    'sign-in':                        'SignIn',
    'account-merge-form':             'SSOAccountMergeForm',
    'sso-resend-account-confirmation':'SSOResendAccountConfirmation',
    'elements-sign-up':               'ElementsSignUpFlow'
  },
  load(root, tries) {
    if (root == null) { root = document.body; }
    if (tries == null) { tries = 0; }
    if (Tutsplus.environment === 'development') {
      console.log('Loading views...');
    }
    let success = true;
    $(".view", root).each((i, el) => {
      const $el = $(el);
      if (!$el.is('.view--loaded') && !$el.parents('.post__content').length) {
        return (() => {
          const result = [];
          for (let className of Array.from(el.className.split(' '))) {
            var viewName;
            if (viewName = this.viewNames[className]) {
              if (!this._loadView($el, viewName)) { result.push(success = false); } else {
                result.push(undefined);
              }
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      }
    });

    if (!success) {
      if (tries < 2) {
        return setTimeout((() => this.load(root, tries + 1)), 100);
      } else {
        return (typeof console !== 'undefined' && console !== null ? console.log("Failed to initialize all views, aborting") : undefined);
      }
    }
  },

  _loadView($el, viewName) {
    let view;
    if (view = window[viewName]) {
      console.log('Found class: ' + viewName);
      new view({el: $el});
      $el.addClass('view--loaded');
      return true;
    } else {
      console.log('Cannot find class: ' + viewName);
      return false;
    }
  },

  _loadViewVanilla(elementsArray, viewName) {
    let view;
    if (view = window[viewName]) {
      console.log('Found class: ' + viewName);
      new view({el: elementsArray});
      for (let element of Array.from(elementsArray)) {
        element.classList.add('view--loaded');
      }
      return true;
    } else {
      console.log('Cannot find class: ' + viewName);
      return false;
    }
  }
};
